scadaApp.config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {

    $locationProvider.html5Mode({
        enabled: true,
        requireBase: true
    });

    $routeProvider.
        when('/login', {
            templateUrl: 'components/login.html'
        })
        .when('/unauthorized', {
            templateUrl: 'components/util/unauthorized.html'
        })
        .when('/refresh', {
            controller: 'RefreshCtrl',
            templateUrl: 'components/refresh/loader.html'
        })
        .when('/dashboard', {
            controller: 'DashboardCtrl',
            templateUrl: 'components/dashboard/dashboard.html'
        })
        .when('/project', {
            controller: 'ProjectCtrl',
            templateUrl: 'components/project/project.html'
        })
        .when('/company', {
            controller: 'CompanyCtrl',
            templateUrl: 'components/company/company.html'
        })
        .when('/event', {
            controller: 'FrontCtrl',
            templateUrl: 'components/event/event.html'
        })
        .when('/graph', {
            controller: 'SummaryCtrl',
            templateUrl: 'components/graph/graph.html'
        })
        .when('/plant', {
            controller: 'PlantCtrl',
            templateUrl: 'components/plant/plant.html'
        })
        .when('/inverter', {
            controller: 'InverterCtrl',
            templateUrl: 'components/inverter/inverter.html'
        })
        .otherwise({
            redirectTo: '/refresh'
        });
}]);