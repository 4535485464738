scadaControllers.controller('InverterCtrl', ['$scope', 'Restangular', 'AccountService', '$location', '$routeParams', function ($scope, Restangular, AccountService, $location, $routeParams) {

    console.log("loading Inverter ctrl");

    $scope.inverterId = $routeParams.id;
    $scope.inverter = {};
    $scope.inverterSummary = {};
    $scope.standby = 0;
    $scope.active = false;
    $scope.loading = true;
    $scope.gettingInverter = "";
    $scope.account = AccountService.getAccount();

    $scope.event = [];
    $scope.faultList = {};
    $scope.warningList = {};


    $scope.getInverterUpdate = function (id) {
        $scope.gettingInverter = "Retrieving Inverter, please wait...";
        $scope.loading = true;
        Restangular.one("update").one("inverter", id)
            .get()
            .then(function (inverterResp) {
                $scope.gettingInverter = "";
                $scope.loading = false;
                if (inverterResp.statusCode == "S1000") {
                    $scope.inverter = inverterResp.content;
                    $scope.standby = ($scope.inverter.operatingState + 1) * $scope.inverter.modBusStatus;
                    $scope.active = new Date() - new Date($scope.inverter.timeStamp) <= 300000;
                    //$scope.event=$scope.inverter.eventFields.toString(2).split('');
                }
            }, function (error) {
                $scope.gettingInverter = "";
                $scope.loading = false;
            });
    };
    $scope.getInverterUpdate($scope.inverterId);

    $scope.getInverter = function (id) {
        $scope.gettingInverter = "Retrieving Inverter, please wait...";
        $scope.loading = true;
        Restangular.one("inverter", id)
            .get()
            .then(function (inverterResp) {
                $scope.gettingInverter = "";
                $scope.loading = false;
                if (inverterResp.statusCode == "S1000") {
                    $scope.inverterSummary = inverterResp.content;
                }
            }, function (error) {
                $scope.gettingInverter = "";
                $scope.loading = false;
            });
    };
    $scope.getInverter($scope.inverterId);



    $scope.getInverterEvent = function (id) {
        Restangular.one("update").one("inverter", id).one("event")
            .get()
            .then(function (eventResp) {
                $scope.loading = false;
                if (eventResp.statusCode == "S1000" && eventResp.content) {
                    $scope.event = eventResp.content.eventFields.toString(2).padStart(16, 0).split('');
                }
            }, function (error) {
                console.log(error);
            });
    };
    $scope.getInverterEvent($scope.inverterId);

    $scope.getInverterFaultList = function (id) {
        Restangular.one("fault").one("inverter", id)
            .get()
            .then(function (eventResp) {
                $scope.loading = false;
                if (eventResp.statusCode == "S1000" && eventResp.content) {
                    $scope.faultList = eventResp.content;
                }
            }, function (error) {
                console.log(error);
            });
    };
    $scope.getInverterFaultList($scope.inverterId);

    $scope.getInverterWarningList = function (id) {
        Restangular.one("warning").one("inverter", id)
            .get()
            .then(function (eventResp) {
                $scope.loading = false;
                if (eventResp.statusCode == "S1000" && eventResp.content) {
                    $scope.warningList = eventResp.content;
                }
            }, function (error) {
                console.log(error);
            });
    };
    $scope.getInverterWarningList($scope.inverterId);


}]);