scadaControllers.controller('SideMenuCtrl', ['$scope', '$route', 'Restangular', 'AccountService', '$http', '$location', function ($scope, $route, Restangular, AccountService, $http, $location) {

    console.log("loading main ctrl");

    $scope.loadingMenu = true;
    $scope.selectProject = {};

    $scope.getInverters = function (plantId,plant) {
        $scope.loading = true;
        Restangular.one("menu").one("plant",plantId).one("inverter")
            .get()
            .then(function (invResp) {
                $scope.loadingMenu = false;
                if (invResp.statusCode == "S1000") {
                    if(plant){
                        plant.inverters=invResp.content;
                    }else{
                        $scope.smInverters = invResp.content;
                    }
                }
            }, function (error) {
                $scope.loadingMenu = false;
            });
    };

    $scope.getPlants = function (companyId,company) {
        $scope.loading = true;
        Restangular.one("menu").one("company",companyId).one("plant")
            .get()
            .then(function (plantResp) {
                $scope.loadingMenu = false;
                if (plantResp.statusCode == "S1000") {

                    if(company){
                        company.plants=plantResp.content;
                    }else{
                        $scope.smPlants = plantResp.content;
                    }
                    angular.forEach($scope.smPlants,function (value, key) {
                        $scope.getInverters(value.id,value);
                    });
                }
            }, function (error) {
                $scope.loadingMenu = false;
            });
    };

    $scope.getCompanies = function (projectId,project) {
        $scope.loading = true;
        Restangular.one("menu").one("project",projectId).one("company")
            .get()
            .then(function (companyResp) {
                $scope.loadingMenu = false;
                if (companyResp.statusCode == "S1000") {
                    angular.forEach(companyResp.content,function (value, key) {
                        $scope.getPlants(value.id,value);
                    });

                    if(project){
                        project.companies=companyResp.content;
                    }else{
                        $scope.smCompanies = companyResp.content;
                    }

                }
            }, function (error) {
                $scope.loadingMenu = false;
            });
    };

    $scope.getProjects = function () {
        $scope.loading = true;
        Restangular.one("menu","project")
            .get()
            .then(function (projectResp) {
                $scope.loadingMenu = false;
                if (projectResp.statusCode == "S1000") {
                    $scope.smProjects = projectResp.content;
                    angular.forEach($scope.smProjects,function (value, key) {
                        $scope.getCompanies(value.id,value);
                    });
                }
            }, function (error) {
                $scope.loadingMenu = false;
            });
    };

    $scope.account = AccountService.getAccount();

    switch ($scope.account.role){
        case 'Admin':
            $scope.getProjects();
            break;
        case 'Chief Manager':
            $scope.getProjects();
            break;
        case  'Project Manager':
            $scope.getCompanies($scope.account.referenceName,null);
            break;
        case  'Company Manager':
            $scope.getPlants($scope.account.referenceName,null);
            break;
        case 'Plant Manager':
            $scope.getInverters($scope.account.referenceName,null);
            break;
    }

}]);