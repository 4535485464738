scadaControllers.controller('AccountCtrl', ['$scope', '$route', 'Restangular', 'AccountService', '$http', '$location', '$interval', function ($scope, $route, Restangular, AccountService, $http, $location, $interval) {

    console.log("loading user ctrl");

    $scope.account = AccountService.getAccount();

    if (!$scope.account.isLoggedIn) {
        window.location = "login.html"
    }

    $scope.logout = function () {
        AccountService.logout();
        location.reload();
    };

    if (!$http.defaults.headers.common.Authorization) {
        $location.url("/refresh?url=" + $location.url());
    }

    $interval(function () {
        $location.url("/refresh?url=" + $location.url());
    }, 3500000);

}]);