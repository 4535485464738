scadaControllers.controller('CompanyCtrl', ['$scope', 'Restangular', 'AccountService', '$location', '$routeParams', function ($scope, Restangular, AccountService, $location, $routeParams) {

    console.log("loading Company ctrl");

    $scope.companyId = $routeParams.id;
    $scope.company = {};
    $scope.plants = {};
    $scope.plantCount = 0;
    $scope.loading = true;
    $scope.loadingGraph = true;
    $scope.gettingPlants = "";
    $scope.gettingCompany = "";
    $scope.gettingPlant = "";
    $scope.gettingAll = "";
    $scope.gettingFactor = "";
    $scope.gettingPower = "";
    $scope.graphs = {};
    $scope.nameMap = {};
    $scope.date={};
    $scope.account = AccountService.getAccount();


    $scope.loadGraphFromScope = function (plantName) {
        if (!$scope.graphs[plantName]) {
            $scope.getAll($scope.nameMap[plantName]);
        }
    };

    drawGraph($scope.loadGraphFromScope);

    $scope.getCompany = function (id) {
        $scope.gettingCompany = "Retrieving Company, please wait...";
        $scope.loading = true;
        Restangular.one("company", id)
            .get()
            .then(function (plantResp) {
                $scope.gettingCompany = "";
                $scope.loading = false;
                if (plantResp.statusCode == "S1000") {
                    $scope.company = plantResp.content;
                }
            }, function (error) {
                $scope.gettingCompany = "";
                $scope.loading = false;
            });
    };
    $scope.getCompany($scope.companyId);

    $scope.changeDate = function () {
        $scope.date.day=$('#day').val();
        $scope.graphs = {};
        resetTemplate();
    };

    $scope.getPlants = function (companyId) {
        $scope.gettingPlants = "Retrieving Plants, please wait...";
        $scope.loading = true;
        Restangular.one("company", companyId).one("plant")
            .get()
            .then(function (plantResp) {
                $scope.gettingPlants = "";
                $scope.loading = false;
                if (plantResp.statusCode == "S1000") {
                    $scope.plants = plantResp.content;
                    angular.forEach($scope.plants,function (value, key) {
                        $scope.nameMap[value.name]=value;
                    });
                    loadTemplate($scope.plants);
                }
            }, function (error) {
                $scope.gettingPlants = "";
                $scope.loading = false;
            });
    };
    $scope.getPlants($scope.companyId);

    $scope.loadPlant = function (plant) {
        $location.url("/plant?id=" + plant.id);
    };

    $scope.getAll = function (plant) {
        $scope.gettingEnergy = "Retrieving Energy, please wait...";
        $scope.loadingGraph = true;
        Restangular.one("graph", "all").one("plant", plant.id)
            .get($scope.date)
            .then(function (plantResp) {
                $scope.gettingEnergy = "";
                $scope.loadingGraph = false;
                if (plantResp.statusCode == "S1000") {
                    loadData(plant.name, plantResp.content);
                    $scope.graphs[plant.name] = true;
                }
            }, function (error) {
                $scope.gettingEnergy = "";
                $scope.loadingGraph = false;
            });
    };

    $scope.getStatusByPlant = function (plant) {
        Restangular.one("current", "fault").one("plant", plant.id)
            .get()
            .then(function (plantResp) {
                if (plantResp.statusCode == "S1000") {
                    plant.faults = plantResp.content;
                }
            }, function (error) {
            });

        Restangular.one("current", "warning").one("plant", plant.id)
            .get()
            .then(function (plantResp) {
                if (plantResp.statusCode == "S1000") {
                    plant.warnings = plantResp.content;
                }
            }, function (error) {
            });
        Restangular.one("current", "offline").one("plant", plant.id)
            .get()
            .then(function (plantResp) {
                if (plantResp.statusCode == "S1000") {
                    plant.offlines = plantResp.content;
                }
            }, function (error) {
            });
    };

}]);