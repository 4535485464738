scadaControllers.controller('NotificationCtrl', ['$scope', '$route', 'Restangular', 'AccountService', '$http', '$location', function ($scope, $route, Restangular, AccountService, $http, $location) {

    console.log("loading main ctrl");

    $scope.loadingFaty = true;
    $scope.loadingWany = true;


    $scope.getFaultByPlant = function (plantId) {
        $scope.loadingFaty = true;
        Restangular.one("fault").one("plant",plantId)
            .get()
            .then(function (invResp) {
                $scope.loadingNoty = false;
                if (invResp.statusCode == "S1000") {
                    $scope.faults = invResp.content;
                }
            }, function (error) {
                $scope.loadingNoty = false;
            });
    };

    $scope.getFaultByCompany = function (companyId) {
        $scope.loadingFaty = true;
        Restangular.one("fault").one("company",companyId)
            .get()
            .then(function (plantResp) {
                $scope.loadingNoty = false;
                if (plantResp.statusCode == "S1000") {
                    $scope.faults = plantResp.content;
                }
            }, function (error) {
                $scope.loadingNoty = false;
            });
    };

    $scope.getFaultByProject = function (projectId) {
        $scope.loadingFaty = true;
        Restangular.one("fault").one("project",projectId)
            .get()
            .then(function (plantResp) {
                $scope.loadingNoty = false;
                if (plantResp.statusCode == "S1000") {
                    $scope.faults = plantResp.content;
                }
            }, function (error) {
                $scope.loadingNoty = false;
            });
    };

    $scope.getAllFaults = function () {
        $scope.loadingFaty = true;
        Restangular.one("fault")
            .get()
            .then(function (projectResp) {
                $scope.loadingNoty = false;
                if (projectResp.statusCode == "S1000") {
                    $scope.faults = projectResp.content;
                }
            }, function (error) {
                $scope.loadingNoty = false;
            });
    };
    

    $scope.getWarningByPlant = function (plantId) {
        $scope.loadingWany = true;
        Restangular.one("warning").one("plant",plantId)
            .get()
            .then(function (invResp) {
                $scope.loadingNoty = false;
                if (invResp.statusCode == "S1000") {
                    $scope.warnings = invResp.content;
                }
            }, function (error) {
                $scope.loadingNoty = false;
            });
    };
    
    $scope.getWarningByCompany = function (companyId) {
        $scope.loadingWany = true;
        Restangular.one("warning").one("company",companyId)
            .get()
            .then(function (invResp) {
                $scope.loadingNoty = false;
                if (invResp.statusCode == "S1000") {
                    $scope.warnings = invResp.content;
                }
            }, function (error) {
                $scope.loadingNoty = false;
            });
    };

    $scope.getWarningByProject = function (projectId) {
        $scope.loadingWany = true;
        Restangular.one("warning").one("project",projectId)
            .get()
            .then(function (plantResp) {
                $scope.loadingNoty = false;
                if (plantResp.statusCode == "S1000") {
                    $scope.warnings = plantResp.content;
                }
            }, function (error) {
                $scope.loadingNoty = false;
            });
    };

    $scope.getAllWarnings = function () {
        $scope.loadingWany = true;
        Restangular.one("warning")
            .get()
            .then(function (projectResp) {
                $scope.loadingNoty = false;
                if (projectResp.statusCode == "S1000") {
                    $scope.warnings = projectResp.content;
                }
            }, function (error) {
                $scope.loadingNoty = false;
            });
    };

    $scope.account = AccountService.getAccount();

    switch ($scope.account.role){
        case 'Admin':
            $scope.getAllFaults();
            $scope.getAllWarnings();
            break;
        case 'Chief Manager':
            //$scope.getAllFaults();
            //$scope.getAllWarnings();
            break;
        case  'Project Manager':
            //$scope.getFaultByProject($scope.account.referenceName);
            //$scope.getWarningByProject($scope.account.referenceName);
            break;
        case  'Company Manager':
            //$scope.getFaultByCompany($scope.account.referenceName);
            //$scope.getWarningByCompany($scope.account.referenceName);
            break;
        case 'Plant Manager':
            $scope.getFaultByPlant($scope.account.referenceName);
            $scope.getWarningByPlant($scope.account.referenceName);
            break;
    }

}]);