scadaControllers.controller('MainCtrl', ['$scope', '$route', 'Restangular', 'AccountService', '$http', '$location', function ($scope, $route, Restangular, AccountService, $http, $location) {

    console.log("loading main ctrl");

    $scope.loading = true;

    $scope.account = AccountService.getAccount();

    $scope.inverterFlag = $scope.account.role == 'Admin' || $scope.account.role == 'Plant Manager'|| $scope.account.role == 'Company Manager';

    $scope.gotoHome = function () {
        if ($scope.account.isLoggedIn) {
            window.location = "index.html"
        } else {
            $route.reload();
        }
    };

    $scope.goToDashboard = function () {
        $location.url("/dashboard");
    };

    $scope.goToProject = function (id) {
        $location.url("/project?id=" + id);
    };

    $scope.goToCompany = function (id) {
        $location.url("/company?id=" + id);
    };

    $scope.goToPlant = function (id) {
        $location.url("/plant?id=" + id);
    };

    $scope.goToInverter = function (id) {
        $location.url("/inverter?id=" + id);
    };

    $scope.reloadRoute = function () {
        $route.reload();
    };

    $scope.isActive = function (viewLocation) {
        return $location.path().indexOf(viewLocation) == 0;
    };
}]);