scadaControllers.controller('ProjectCtrl', ['$scope', 'Restangular', 'AccountService', '$location', '$routeParams', function ($scope, Restangular, AccountService, $location, $routeParams) {

    console.log("loading Dashboard ctrl");

    $scope.projectId = $routeParams.id;
    $scope.project = {};
    $scope.companies = {};
    $scope.loading = true;
    $scope.gettingCompanies = "";
    $scope.gettingProject = "";
    $scope.account = AccountService.getAccount();

    $scope.getProject = function (id) {
        $scope.gettingProject = "Retrieving Project, please wait...";
        $scope.loading = true;
        Restangular.one("project", id)
            .get()
            .then(function (companyResp) {
                $scope.gettingProject = "";
                $scope.loading = false;
                if (companyResp.statusCode == "S1000") {
                    $scope.project = companyResp.content;
                }
            }, function (error) {
                $scope.gettingProject = "";
                $scope.loading = false;
            });
    };
    $scope.getProject($scope.projectId);


    $scope.getCompanies = function (projectId) {
        $scope.gettingCompanies = "Retrieving Companies, please wait...";
        $scope.loading = true;
        Restangular.one("project", projectId).one("company")
            .get()
            .then(function (companyResp) {
                $scope.gettingCompanies = "";
                $scope.loading = false;
                if (companyResp.statusCode == "S1000") {
                    $scope.companies = companyResp.content;
                }
            }, function (error) {
                $scope.gettingCompanies = "";
                $scope.loading = false;
            });
    };
    $scope.getCompanies($scope.projectId);

    $scope.getStatusByCompany = function (company) {
        Restangular.one("current","fault").one("company",company.id)
            .get()
            .then(function (companyResp) {
                if (companyResp.statusCode == "S1000") {
                    company.faults = companyResp.content;
                }
            }, function (error) {
            });

        Restangular.one("current","warning").one("company",company.id)
            .get()
            .then(function (companyResp) {
                if (companyResp.statusCode == "S1000") {
                    company.warnings = companyResp.content;
                }
            }, function (error) {
            });

        Restangular.one("current","offline").one("company",company.id)
            .get()
            .then(function (companyResp) {
                if (companyResp.statusCode == "S1000") {
                    company.offlines = companyResp.content;
                }
            }, function (error) {
            });
    };

    $scope.loadCompany = function (company) {
        $location.url("/company?id=" + company.id);
    };

}]);