scadaControllers.controller('RefreshCtrl', ['$scope', '$route', '$routeParams', 'Restangular', 'AccountService', '$http', '$httpParamSerializer', '$location', function ($scope, $route, $routeParams, Restangular, AccountService, $http, $httpParamSerializer, $location) {

    console.log("loading main ctrl");

    var refreshToken = window.localStorage.getItem("refreshToken");
    $scope.account=AccountService.getAccount();

    var scada = {
        grant_type: "refresh_token",
        client_id: "scada-client",
        client_secret: "scada-secret",
        refresh_token: refreshToken
    };

    var req = {
        method: 'POST',
        url: Prop.scadaServiceBaseUrl + "/oauth/token",
        headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
            "Authorization": undefined
        },
        data: $httpParamSerializer(scada)
    };

    $http(req)
        .then(function successCallback(response) {

            console.log("Get request Success : " + response.data.access_token);
            window.localStorage.setItem("refreshToken", response.data.refresh_token);

            $http.defaults.headers.common = {"Authorization": "Bearer " + response.data.access_token};

            if($routeParams.url){
                $location.url($routeParams.url);
            }else{
                switch ($scope.account.role){
                    case 'Admin':
                        $location.url("/dashboard");
                        break;
                    case 'Chief Manager':
                        $location.url("/dashboard");
                        break;
                    case  'Company Manager':
                        $location.url("/company?id="+$scope.account.referenceName);
                        break;
                    case  'Project Manager':
                        $location.url("/project?id="+$scope.account.referenceName);
                        break;
                    case 'Plant Manager':
                        $location.url("/plant?id="+$scope.account.referenceName);
                        break;
                }
            }

        }, function errorCallback(response) {
            AccountService.logout();
            location.reload();
        });
}]);