scadaControllers.controller('SigninCtrl', ['$scope', 'Restangular', 'AccountService', '$http', '$httpParamSerializer', '$location', function ($scope, Restangular, AccountService, $http, $httpParamSerializer, $location) {

    console.log("loading signin ctrl");

    $scope.userLoading = false;
    $scope.loginError = "";
    $scope.registerError = "";
    $scope.scada = {
        grant_type: "password",
        username: "",
        password: "",
        client_id: "scada-client",
        client_secret: "scada-secret",
        scope: "write"
    };

    $scope.scadaNew = {};
    $scope.passwordRe = "";

    $scope.showRegister = false;
    $scope.account = AccountService.getAccount();


    if ($scope.account.isLoggedIn) {
        window.location = "index.html";
    }

    $scope.signIn = function () {
        $scope.userLoading = true;
        $scope.loginError = "";

        var req = {
            method: 'POST',
            url: Prop.scadaServiceBaseUrl + "/oauth/token",
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=utf-8"
            },
            data: $httpParamSerializer($scope.scada)
        };

        $http(req)
            .then(function successCallback(response) {
                AccountService.loadAccount($scope.scada.username,response.data.role,response.data.referenceName, response.data.authorities,response.data.refresh_token, $scope.gotoHome);
            }, function errorCallback(response) {
                $scope.userLoading = false;
                $scope.loginError=response.data.error_description;
            });
    };

    $scope.registerNew = function () {
        $scope.registerError = "";
        if ($scope.scadaNew.password == $scope.passwordRe) {
            $scope.userLoading = true;
            Restangular.one(Prop.scadaUrl).one("account")
                .post("", $scope.scadaNew).then(function (registerResp) {
                    console.log("Register scada response: " + registerResp.statusCode);
                    if (registerResp.statusCode == 'S1000') {
                        var token = registerResp.content.accessToken;
                        if (token) {
                            AccountService.loadAccount($scope.scadaNew.name, registerResp.content.type, token, $scope.gotoHome);
                        } else {
                            $scope.userLoading = false;
                            $scope.registerError = 'Sorry, We are unable to Create this moment, please try in short while'
                        }
                    } else {
                        $scope.userLoading = false;
                        $scope.registerError = 'Sorry, We are unable to Create, because '
                            + registerResp.statusDescription;
                    }
                }, function (error) {
                    $scope.userLoading = false;
                    $scope.registerError = 'Sorry, We are unable to Create this moment, please try in short while';
                });
        } else {
            $scope.registerError = "Passwords doesn't match";
        }
    };

}]);