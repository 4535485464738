scadaControllers.controller('DashboardCtrl', ['$scope', 'Restangular', 'AccountService', '$location', function ($scope, Restangular, AccountService, $location) {

    console.log("loading Dashboard ctrl");

    $scope.projects = {};
    $scope.loading = true;
    $scope.gettingProjects = "";
    $scope.accName = AccountService.getAccount().name;

    $scope.colors = {akbar: "text-blue", suryadhanavi: 'text-green'};

    $scope.labels = ["Active Inverters", "Inactive Inverters"];
    $scope.data = [3, 5];
    $scope.options = {
        elements: {
            center: {
                color: '#000000', // Default is #000000
                fontStyle: 'Arial', // Default is Arial
                sidePadding: 20 // Defualt is 20 (as a percentage)
            }
        }
    };

    $scope.activeColors = ['#00FF7F', '#FA8072'];

    $scope.getProjects = function () {
        $scope.gettingProjects = "Retrieving Projects, please wait...";
        $scope.loading = true;
        Restangular.one("project")
            .get()
            .then(function (projectResp) {
                $scope.gettingProjects = "";
                $scope.loading = false;
                if (projectResp.statusCode == "S1000") {
                    $scope.projects = projectResp.content;
                }
            }, function (error) {
                $scope.gettingProjects = "";
                $scope.loading = false;
            });
    };

    $scope.getStatusByProject = function (project) {
        Restangular.one("current","fault").one("project",project.id)
            .get()
            .then(function (projectResp) {
                if (projectResp.statusCode == "S1000") {
                    project.faults = projectResp.content;
                }
            }, function (error) {
            });

        Restangular.one("current","warning").one("project",project.id)
            .get()
            .then(function (projectResp) {
                if (projectResp.statusCode == "S1000") {
                    project.warnings = projectResp.content;
                }
            }, function (error) {
            });

        Restangular.one("current","offline").one("project",project.id)
            .get()
            .then(function (projectResp) {
                if (projectResp.statusCode == "S1000") {
                    project.offlines = projectResp.content;
                }
            }, function (error) {
            });
    };
    $scope.getProjects();


    $scope.loadProject = function (project) {
        $location.url("/project?id=" + project.id);
    };


}]);