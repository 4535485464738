'use strict';

var scadaApp = angular.module('scadaApp', [
    'ui.bootstrap',
    'restangular',
    'ngRoute',
    'scadaServices',
    'scadaControllers',
    'scadaDirectives',
    'scadaFilters',
    'ngSanitize',
    'ngCsv'
]);

var scadaServices = angular.module('scadaServices', []);
var scadaControllers = angular.module('scadaControllers', []);
var scadaDirectives = angular.module('scadaDirectives', []);

var scadaFilters = angular.module('scadaFilters', []).filter('checkmark', function () {
    return function (input) {
        return input ? '\u2713' : '\u2718';
    };
});


scadaApp.run(['Restangular', 'AccountService', '$location', '$http', '$rootScope', '$anchorScroll', function (Restangular, AccountService, $location, $http, $rootScope, $anchorScroll) {

    console.log("%cStop!, This might not do what you think", "background: red; color: yellow; font-size: x-large");

    Restangular.setBaseUrl(Prop.scadaServiceBaseUrl);

    /*Restangular.setRequestInterceptor(
        function (request) {
            if(!$http.defaults.headers.common.Authorization){
                return AccountService.doRefreshToken();
            }
        }
    );*/

    Restangular.setErrorInterceptor(
        function (response) {
            if (response.status == 401 || response.status == 0) {
                //AccountService.logout();
                location.reload();
            } else if (response.status == 403) {
                $location.url("/unauthorized");
            }
            else {
                // Some other unknown Error.
                console.log(response);
            }
            // Stop the promise chain.
            return false;
        }
    );

    $rootScope.scadaToLocalTimeString = function (date) {
        return (new Date(date)).toLocaleTimeString();
    };

    $rootScope.scadaToLocalDateString = function (date) {
        return (new Date(date)).toLocaleDateString();
    };

    $rootScope.prettyName = function (name) {
        var pretty = "Anonymous";
        if (!name) {
            return pretty;
        }

        if (name.length < 13) {
            return name;
        } else {

            if (name.indexOf(" ") == '-1') {
                return name.substring(0, 9) + "..";
            }

            var parts = name.split(' ');
            if (parts[0].length < 13 && parts[0].length > 4) {
                pretty = parts[0];
            } else if (parts[1].length < 13 && parts[1].length > 4) {
                pretty = parts[1];
            }

            if (pretty != "Anonymous") {
                return pretty;
            } else {
                return name.substring(0, 9) + "..";
            }

        }

    };


}]);
