scadaServices.factory('AccountService', ['Restangular', '$http', '$location', '$httpParamSerializer', function (Restangular, $http, $location, $httpParamSerializer) {
    var account = {
        name: "",
        role: "",
        referenceName: "",
        authorities: "",
        isLoggedIn: false
    };

    var savedUserString = window.localStorage.getItem("scadaAccount");
    if (savedUserString) {
        var savedAccount = JSON.parse(atob(savedUserString));
        account.name = savedAccount.name;
        account.role = savedAccount.role;
        account.referenceName = savedAccount.referenceName;
        account.authorities = savedAccount.authorities;
        account.isLoggedIn = true;
        console.log("Loading cached Account : " + account.name);
        console.log(account.authorities[0].authority);
    }

    return {
        loadAccount: function (name,role,referenceName, authorities, refreshToken, callback) {

            account.isLoggedIn = true;
            account.name = name;
            account.role = role;
            account.referenceName = referenceName;
            account.authorities = authorities;

            window.localStorage.setItem("refreshToken", refreshToken);
            window.localStorage.setItem("scadaAccount", btoa(JSON.stringify(account)));

            callback();
        },
        logout: function () {
            account.name = "";
            account.role = "";
            account.referenceName = "";
            account.authorities = "";
            account.isLoggedIn = false;
            window.localStorage.removeItem("scadaAccount");
            window.localStorage.removeItem("refreshToken");
            $http.defaults.headers.common = {"Authorization": ""};
        },
        getAccount: function () {
            return account;
        }
    };


}]);