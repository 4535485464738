scadaControllers.controller('PlantCtrl', ['$scope', 'Restangular', 'AccountService', '$location', '$routeParams', function ($scope, Restangular, AccountService, $location, $routeParams) {

    console.log("loading Dashboard ctrl");

    $scope.plantId = $routeParams.id;
    $scope.plant = {};
    $scope.inverter = {};
    $scope.inverters = {};
    $scope.energy = [];
    $scope.loading = true;
    $scope.loadingGraph = true;
    $scope.gettingInverters = "";
    $scope.gettingPlant = "";
    $scope.gettingEnergy = "";
    $scope.account = AccountService.getAccount();

    $scope.filename = "test";
    $scope.getArray = [];

    $scope.date = {};

    if (!$routeParams.from || !$routeParams.to) {
        var date = new Date();
        $scope.date.to = date.getFullYear() + '-'
            + ((date.getMonth() + 1) < 10 ? 0 : "") + (date.getMonth() + 1) + '-'
            + ((date.getDate()) < 10 ? 0 : "") + (date.getDate());
        date.setMonth(date.getMonth() - 1);
        $scope.date.from = date.getFullYear() + '-'
            + ((date.getMonth() + 1) < 10 ? 0 : "") + (date.getMonth() + 1) + '-'
            + ((date.getDate()) < 10 ? 0 : "") + (date.getDate());
    } else {
        $scope.date.from = $routeParams.from;
        $scope.date.to = $routeParams.to;
    }


    $scope.getPlant = function (id) {
        $scope.gettingPlant = "Retrieving Plant, please wait...";
        $scope.loading = true;
        Restangular.one("plant", id)
            .get()
            .then(function (plantResp) {
                $scope.gettingPlant = "";
                $scope.loading = false;
                if (plantResp.statusCode == "S1000") {
                    $scope.plant = plantResp.content;
                    $scope.getDailyEnergy();
                }
            }, function (error) {
                $scope.gettingPlant = "";
                $scope.loading = false;
            });
    };


    $scope.getDailyEnergy = function () {
        console.log($scope.date);
        $scope.gettingEnergy = "Retrieving Energy, please wait...";
        $scope.loadingGraph = true;
        Restangular.one("graph", "energy").one("plant", $scope.plantId)
            .get($scope.date)
            .then(function (plantResp) {
                $scope.gettingEnergy = "";
                $scope.loadingGraph = false;
                if (plantResp.statusCode == "S1000") {
                    $scope.energy = plantResp.content;
                    drawGraph($scope.energy);

                    if ($scope.date.from == $scope.date.to) {
                        $scope.filename = $scope.plant.name + "_" + $scope.date.from;
                    } else {
                        $scope.filename = $scope.plant.name + "_" + $scope.date.from + "-" + $scope.date.to;
                    }
                    $scope.getArray = [{"Date": "Date", "Energy": "Energy (kWh)", "Inverters": "Inverters"}];
                    angular.forEach(plantResp.content, function (value) {
                        $scope.getArray.push({
                            "Date": value.id.day,
                            "Energy": value.energy,
                            "Inverters": value.inverters
                        })
                    });

                }
            }, function (error) {
                $scope.gettingEnergy = "";
                $scope.loadingGraph = false;
            });
    };
    $scope.getPlant($scope.plantId);


    $scope.getInverters = function (id) {
        $scope.gettingInverters = "Retrieving Inverter, please wait...";
        Restangular.one("plant", id).one("inverter")
            .get()
            .then(function (plantResp) {
                $scope.gettingInverters = "";
                if (plantResp.statusCode == "S1000") {
                    $scope.inverters = plantResp.content;
                    $scope.inverter = $scope.inverters[0];
                }
            }, function (error) {
                $scope.gettingInverters = "";
            });
    };
    $scope.getInverters($scope.plantId);


    $scope.loadInverter = function (inverter) {
        if($scope.inverterFlag){
            $location.url("/inverter?id=" + inverter.id);
        }
    };


    $scope.loadGraph = function () {
        $scope.date.from = $('#from').val();
        $scope.date.to = $('#to').val();
        $scope.getDailyEnergy();
    };

}]);